export const openUrlInPopup = (url: string, target: string) => {
  const width = 500
  const height = 600
  const left = window.screen.width / 2 - width / 2
  const top = window.screen.height / 2 - height / 2
  const windowFeatures = `width=${width},height=${height},top=${top},left=${left}`
  const popup = window.open(url, target, windowFeatures)
  if (!popup) {
    throw new Error("Failed to open popup window")
  }
  return popup
}
