import { useTheme } from "../hooks/useTheme.js"

const ThemeButton: React.FC = () => {
  const [, setTheme] = useTheme()

  // Show 3 options on click: Light mode, Dark mode, System mode
  return (
    <div className="group absolute right-0 top-0 mr-2 mt-2 flex min-w-5 ">
      <button
        className="hidden group-hover:block"
        onClick={() => setTheme("light")}
      >
        🌞
      </button>
      <button
        className="hidden group-hover:block"
        onClick={() => setTheme("dark")}
      >
        🌑
      </button>
      <button
        className="hidden group-hover:block"
        onClick={() => setTheme("system")}
      >
        🌑/🌞
      </button>
      <button className="hidden group-hover:hidden dark:block">🌑</button>
      <button className="block group-hover:hidden dark:hidden">🌞</button>
    </div>
  )
}

export default ThemeButton
