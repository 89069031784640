import { useAuth } from "../hooks/useAuth"

interface Props {
  children: React.ReactNode
}

export const AuthGuard: React.FC<Props> = ({ children }) => {
  const {
    user,
    loading,
    error,
    claims,
    signOut,
    signInWithGoogle,
    signInWithPhone,
  } = useAuth()
  // TODO(arie): Make different pages instead
  const SignOutComponent = () => {
    return (
      <div>
        <br />
        <button onClick={signOut}>Sign Out</button>
      </div>
    )
  }
  if (loading) {
    return <div>Loading...</div>
  }
  if (error) {
    return (
      <div>
        Error: {JSON.stringify(error)}
        <SignOutComponent />
      </div>
    )
  }
  if (!user || !claims) {
    return (
      <div className="flex flex-col items-center justify-center">
        <h1>Sign In Page</h1>
        <button
          onClick={signInWithGoogle}
          className="flex w-64 items-center justify-center"
        >
          <svg viewBox="0 0 1024 1024" className="mr-2 w-6 fill-current">
            <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm167 633.6C638.4 735 583 757 516.9 757c-95.7 0-178.5-54.9-218.8-134.9C281.5 589 272 551.6 272 512s9.5-77 26.1-110.1c40.3-80.1 123.1-135 218.8-135 66 0 121.4 24.3 163.9 63.8L610.6 401c-25.4-24.3-57.7-36.6-93.6-36.6-63.8 0-117.8 43.1-137.1 101-4.9 14.7-7.7 30.4-7.7 46.6s2.8 31.9 7.7 46.6c19.3 57.9 73.3 101 137 101 33 0 61-8.7 82.9-23.4 26-17.4 43.2-43.3 48.9-74H516.9v-94.8h230.7c2.9 16.1 4.4 32.8 4.4 50.1 0 74.7-26.7 137.4-73 180.1z" />
          </svg>
          Sign in with Google
        </button>
        <button
          onClick={signInWithPhone}
          className="m-2 flex w-64 items-center justify-center"
        >
          <svg viewBox="0 0 1024 1024" className="mr-2 w-6 fill-current">
            <path d="M885.6 230.2L779.1 123.8a80.83 80.83 0 00-57.3-23.8c-21.7 0-42.1 8.5-57.4 23.8L549.8 238.4a80.83 80.83 0 00-23.8 57.3c0 21.7 8.5 42.1 23.8 57.4l83.8 83.8A393.82 393.82 0 01553.1 553 395.34 395.34 0 01437 633.8L353.2 550a80.83 80.83 0 00-57.3-23.8c-21.7 0-42.1 8.5-57.4 23.8L123.8 664.5a80.89 80.89 0 00-23.8 57.4c0 21.7 8.5 42.1 23.8 57.4l106.3 106.3c24.4 24.5 58.1 38.4 92.7 38.4 7.3 0 14.3-.6 21.2-1.8 134.8-22.2 268.5-93.9 376.4-201.7C828.2 612.8 899.8 479.2 922.3 344c6.8-41.3-6.9-83.8-36.7-113.8z" />
          </svg>
          Sign in with Phone
        </button>
      </div>
    )
  }
  if (!claims.isApproved) {
    return (
      <div>
        <div>😅 You are on the waitlist...</div>
        <SignOutComponent />
      </div>
    )
  }
  return (
    <div>
      {children}
      <SignOutComponent />
    </div>
  )
}

export default AuthGuard
