import { StrictMode } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"

import AuthGuard from "./components/AuthGuard"
import SignInWithPhone from "./components/SignInWithPhone/SignInWithPhone"
import ThemeButton from "./components/ThemeButton"
import { AuthProvider, useAuth } from "./hooks/useAuth"
import { useTheme } from "./hooks/useTheme"

export const SIGN_IN_WITH_PHONE_PATH = "/auth/phone"

const Welcome = () => {
  const { user, error } = useAuth()
  /* TODO(arie): use pages instead*/
  if (error) {
    console.error(error)
  }
  const profilePicture = user?.photoURL ? (
    <img
      width={64}
      height={64}
      src={user.photoURL}
      alt="Profile Picture"
      referrerPolicy="no-referrer"
    />
  ) : null
  return (
    <>
      <h1>Gadol</h1>
      <h2>Welcome to {import.meta.env.VITE_FIREBASE_PROJECT_ID}</h2>
      {profilePicture}
      <div>
        <b>{user?.displayName}</b>
      </div>
      <div>{user ? user.email : "None"}</div>
    </>
  )
}

const App: React.FC = () => {
  useTheme()
  return (
    <StrictMode>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route
              path={SIGN_IN_WITH_PHONE_PATH}
              element={<SignInWithPhone />}
            />
            <Route
              path="/"
              element={
                <>
                  <ThemeButton />
                  <AuthGuard>
                    <Welcome />
                  </AuthGuard>
                </>
              }
            />
            <Route path="*" element={<div>Not found</div>} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </StrictMode>
  )
}

export default App
