import { useEffect, useState } from "react"

type _Mode = "dark" | "light"
type Theme = "dark" | "light" | "system"

type ThemeHook = [Theme, (theme: Theme) => void]

const _storedThemeKey = "gadol__theme"

const _getStoredTheme = (): Theme => {
  const storedTheme = localStorage.getItem(_storedThemeKey) as Theme | null
  return storedTheme || "system"
}

const _storeTheme = (theme: Theme) => {
  localStorage.setItem(_storedThemeKey, theme)
}

const _getMediaQuery = () => window.matchMedia("(prefers-color-scheme: dark)")

export const useTheme = (): ThemeHook => {
  const [theme, setTheme] = useState<Theme>(_getStoredTheme())
  const [systemMode, setSystemMode] = useState<_Mode>(
    _getMediaQuery().matches ? "dark" : "light",
  )

  useEffect(() => {
    const mediaQuery = _getMediaQuery()
    const mediaChangeListener = () =>
      setSystemMode(mediaQuery.matches ? "dark" : "light")
    mediaQuery.addEventListener("change", mediaChangeListener)
    return () => mediaQuery.removeEventListener("change", mediaChangeListener)
  }, [])

  useEffect(() => {
    const classList = window.document.documentElement.classList
    classList.remove("dark", "light")
    classList.add(theme === "system" ? systemMode : theme)
  }, [systemMode, theme])

  const setAndStoreTheme = (theme: Theme) => {
    setTheme(theme)
    _storeTheme(theme)
  }

  return [theme, setAndStoreTheme]
}
