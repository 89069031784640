import { FirebaseError } from "firebase/app"
import type {
  ApplicationVerifier,
  Auth,
  AuthError,
  ConfirmationResult,
} from "firebase/auth"
import { signInWithPhoneNumber } from "firebase/auth"
import { useCallback, useState } from "react"
import PhoneInput from "react-phone-number-input"

import { useAuth } from "../../hooks/useAuth"

interface Props {
  auth: Auth
  setConfirmationResult: (confirmationResult: ConfirmationResult) => void
  appVerifier: ApplicationVerifier
}

const SendPhoneCode: React.FC<Props> = ({
  auth,
  setConfirmationResult,
  appVerifier,
}) => {
  const [phoneNumber, setPhoneNumber] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)
  const [internalError, setInternalError] = useState<string>("")

  const { setError } = useAuth()

  const sendConfirmationCode = useCallback(async () => {
    setLoading(true)
    if (!phoneNumber) {
      // TODO(arie): check if valid phone number
      setInternalError("Invalid phone number try again")
      setLoading(false)
      return
    }
    try {
      const result = await signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      setConfirmationResult(result)
    } catch (err) {
      if (err instanceof FirebaseError) {
        setError(err as AuthError)
        window.opener !== null ? window.close() : (window.location.href = "/")
      } else {
        setInternalError(JSON.stringify(err))
      }
    } finally {
      setLoading(false)
    }
  }, [phoneNumber, auth, appVerifier, setConfirmationResult, setError])

  return (
    <>
      <div>
        <h1 className="text-4xl font-bold">Phone Number</h1>
        <PhoneInput
          defaultCountry="US"
          countryOptionsOrder={["US", "FR"]}
          value={phoneNumber}
          onChange={setPhoneNumber}
          className="m-auto w-1/2"
          disabled={loading}
          type="tel"
        />
        <button className="mt-8" onClick={sendConfirmationCode}>
          Send a code
        </button>
      </div>
      {internalError ? (
        <div className="font-semibold text-red-600">Error: {internalError}</div>
      ) : null}
    </>
  )
}

export default SendPhoneCode
