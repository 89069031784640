import type { ConfirmationResult } from "firebase/auth"
import { RecaptchaVerifier, getAuth } from "firebase/auth"
import { useEffect, useRef, useState } from "react"

import ConfirmCode from "./ConfirmCode"
import SendPhoneCode from "./SendPhoneCode"

const SignInWithPhone: React.FC = () => {
  const [confirmationResult, setConfirmationResult] =
    useState<ConfirmationResult>()
  const captchaRef = useRef<HTMLDivElement>(null)
  const [verifier, setVerifier] = useState<RecaptchaVerifier>()

  const auth = getAuth()

  useEffect(() => {
    if (verifier) {
      return
    }
    setVerifier(
      new RecaptchaVerifier(auth, captchaRef.current!, {
        size: "invisible",
      }),
    )
  }, [auth, verifier])

  return (
    <>
      <div className="flex flex-col justify-center text-center ">
        {confirmationResult ? (
          <ConfirmCode confirmationResult={confirmationResult} />
        ) : verifier ? (
          <SendPhoneCode
            auth={auth}
            setConfirmationResult={setConfirmationResult}
            appVerifier={verifier}
          />
        ) : null}
      </div>
      <div id="captcha" ref={captchaRef}></div>
    </>
  )
}

export default SignInWithPhone
