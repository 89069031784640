import type { FirebaseApp, FirebaseOptions } from "firebase/app"
import { initializeApp } from "firebase/app"
import {
  browserLocalPersistence,
  browserPopupRedirectResolver,
  browserSessionPersistence,
  initializeAuth,
} from "firebase/auth"

function _getFirebaseConfig(): FirebaseOptions {
  const config = {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
  }
  Object.entries(config).forEach(([key, value]) => {
    if (value === undefined) {
      throw new Error(`Missing firebase config value: ${key}`)
    }
  })
  return config
}

function _getFirebaseAuth(app: FirebaseApp) {
  return initializeAuth(app, {
    persistence: [browserLocalPersistence, browserSessionPersistence],
    popupRedirectResolver: browserPopupRedirectResolver,
  })
}

const app = initializeApp(_getFirebaseConfig())
export const auth = _getFirebaseAuth(app)
