import { FirebaseError } from "firebase/app"
import type { AuthError, ConfirmationResult } from "firebase/auth"
import { useState } from "react"
import VerificationInput from "react-verification-input"

import { useAuth } from "../../hooks/useAuth"

interface Props {
  confirmationResult: ConfirmationResult
}

const ConfirmCode: React.FC<Props> = ({ confirmationResult }) => {
  const [code, setCode] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)
  const [internalError, setInternalError] = useState<string>()

  const { setError, setUserCredential } = useAuth()

  const onComplete = async (completeCode: string) => {
    setLoading(true)
    setCode(completeCode)
    try {
      const userCredential = await confirmationResult.confirm(completeCode)
      setUserCredential(userCredential)
      window.opener !== null ? window.close() : (window.location.href = "/")
    } catch (err) {
      if (err instanceof FirebaseError) {
        setError(err as AuthError)
        window.opener !== null ? window.close() : (window.location.href = "/")
      } else {
        setInternalError(JSON.stringify(err))
      }
    } finally {
      setCode("")
      setLoading(false)
    }
  }

  return (
    <>
      <div>
        <h1 className="text-4xl font-bold">Confirmation Code</h1>
        <VerificationInput
          length={6}
          value={code}
          onChange={setCode}
          onComplete={onComplete}
          inputProps={{
            disabled: loading,
            inputMode: "numeric",
            type: "number",
          }}
          classNames={{
            container: "m-auto",
          }}
        />
      </div>
      {internalError ? (
        <div className="font-semibold text-red-600">Error: {internalError}</div>
      ) : null}
    </>
  )
}

export default ConfirmCode
