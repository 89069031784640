import type { AuthError, UserCredential } from "firebase/auth"
import { useEffect, useRef, useState } from "react"
import type { AuthActionHook } from "react-firebase-hooks/auth/dist/auth/types"

import { SIGN_IN_WITH_PHONE_PATH } from "../App"
import { openUrlInPopup } from "../windowUtils"
import useBroadcastState from "./useBroadcastState"

declare type SignInWithPhoneHook = [
  ...AuthActionHook<() => Promise<UserCredential | undefined>>,
  setError: (error: AuthError) => void,
  setUserCredential: (userCredential: UserCredential) => void,
]

export const useSignInWithPhone = (): SignInWithPhoneHook => {
  const [error, _setError] = useBroadcastState<AuthError | undefined>(
    "__sign-in-phone-error",
    undefined,
  )
  const [userCredential, _setUserCredential] = useBroadcastState<
    UserCredential | undefined
  >("__sign-in-phone-credentials", undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const userCredentialRef = useRef(userCredential)

  useEffect(() => {
    if (userCredential) {
      userCredentialRef.current = userCredential
    }
  }, [userCredential])

  const _openSignIn = async () => {
    setLoading(true)
    const popup = openUrlInPopup(SIGN_IN_WITH_PHONE_PATH, "_signInWithPhone")
    await new Promise<void>((resolve /*reject*/) => {
      const interval = setInterval(() => {
        if (popup.closed) {
          popup.close()
          clearInterval(interval)
          resolve()
        }
      }, 500)
    })

    setLoading(false)
    return userCredentialRef.current
  }

  const setError = (error: AuthError | undefined) => {
    _setUserCredential(undefined)
    _setError(error)
    setLoading(false)
  }

  const setUserCredential = (userCredential: UserCredential) => {
    _setUserCredential(userCredential)
    _setError(undefined)
    setLoading(false)
  }

  return [
    // Similar to any other AuthActionHook
    _openSignIn,
    userCredential,
    loading,
    error,

    // Additional methods used by the sign in component
    setError,
    setUserCredential,
  ]
}
