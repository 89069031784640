import type { AuthError, UserCredential } from "firebase/auth"
import { type User } from "firebase/auth"
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { useAuthState, useSignInWithGoogle } from "react-firebase-hooks/auth"
import { useSignOut } from "react-firebase-hooks/auth"

import { auth } from "../firebase"
import type { CustomClaims } from "../types/auth"
import { useSignInWithPhone } from "./useSignInWithPhone"

interface AuthContextType {
  user: User | undefined
  loading: boolean
  claims: CustomClaims | undefined
  error: Error | undefined
  signInWithGoogle: () => Promise<void>
  signInWithPhone: () => Promise<void>
  signOut: () => Promise<boolean>

  // Extra attributes used by custom sign in components
  setError: (error: AuthError) => void
  setUserCredential: (userCredential: UserCredential) => void
}

const AuthContext = createContext<AuthContextType>({
  user: undefined,
  loading: false,
  error: undefined,
  claims: undefined,
  signInWithGoogle: async () => {},
  signInWithPhone: async () => {},
  signOut: () => Promise.resolve(false),

  // Extra context for phone sign in
  setError: () => {},
  setUserCredential: () => {},
})

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, userLoading, userError] = useAuthState(auth)
  const [signOut, signOutLoading, signOutError] = useSignOut(auth)
  const [claimsLoading, setClaimsLoading] = useState<boolean>(true)
  const [claimsError, setClaimsError] = useState<Error | undefined>()
  const [claims, setClaims] = useState<CustomClaims | undefined>()
  const [_signInWithGoogle, , googleLoading, googleError] =
    useSignInWithGoogle(auth)
  const [
    signInWithPhone,
    ,
    phoneLoading,
    phoneError,
    setError,
    setUserCredential,
  ] = useSignInWithPhone()

  const _refreshClaims = useCallback(async (user: User | undefined | null) => {
    if (!user) {
      setClaims(undefined)
      setClaimsLoading(false)
      return
    }
    setClaimsLoading(true)
    try {
      const tokenResult = await user.getIdTokenResult()
      setClaims(tokenResult.claims as unknown as CustomClaims)
    } catch (err) {
      setClaimsError(Error("Error fetching claims"))
    } finally {
      setClaimsLoading(false)
    }
  }, [])

  useEffect(() => {
    void _refreshClaims(user)
  }, [_refreshClaims, user])

  const signInWithGoogle = useCallback(async () => {
    await _signInWithGoogle(
      [
        "https://www.googleapis.com/auth/userinfo.email",
        "https://www.googleapis.com/auth/userinfo.profile",
      ],
      {
        prompt: "select_account",
      },
    )
  }, [_signInWithGoogle])

  const value: AuthContextType = {
    user: user ?? undefined,
    loading:
      userLoading ||
      claimsLoading ||
      signOutLoading ||
      googleLoading ||
      phoneLoading,
    error:
      userError || signOutError || claimsError || googleError || phoneError,
    claims: claims,
    signOut,
    signInWithGoogle,
    signInWithPhone: async () => {
      await signInWithPhone()
    },
    setError,
    setUserCredential,
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = (): AuthContextType => {
  return useContext(AuthContext)
}
