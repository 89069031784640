import { useEffect, useRef, useState } from "react"

function useBroadcastState<T>(
  key: string,
  initialValue: T,
): [T, (value: React.SetStateAction<T>) => void] {
  const [state, setState] = useState<T>(initialValue)
  const channelRef = useRef<BroadcastChannel | null>(null)

  useEffect(() => {
    const channel = new BroadcastChannel(key)
    channelRef.current = channel

    const handleMessage = (event: MessageEvent<string>) => {
      try {
        const parsedData = (
          event.data ? JSON.parse(event.data) : event.data
        ) as T
        setState(parsedData)
      } catch (error) {
        console.error("Failed to parse message data:", error)
      }
    }

    channel.addEventListener("message", handleMessage)

    return () => {
      channel.removeEventListener("message", handleMessage)
      channel.close()
    }
  }, [key])

  const setBroadcastState = (value: React.SetStateAction<T>) => {
    const valueToStore = value instanceof Function ? value(state) : value
    setState(valueToStore)

    if (channelRef.current) {
      try {
        const serializedData = JSON.stringify(valueToStore)
        channelRef.current.postMessage(serializedData)
      } catch (error) {
        console.error("Failed to serialize state:", error)
      }
    }
  }

  return [state, setBroadcastState]
}

export default useBroadcastState
